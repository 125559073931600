<template>
  <div class="questionnaire-main" v-if="previewWalls">
    <div class="preview-logo">
      <img v-if="previewWalls.logo" :src="previewWalls.logo" alt="LOGO" />
      <img v-else src="@/assets/images/logo-multicolour.png" alt="LOGO" />
    </div>
    <div class="questionnaire-title">
      <div>问卷列表</div>
      <img src="@/assets/images/appLications/questionnaire.png" alt />
    </div>
    <div class="questionnaire-list">
      <div
        class="questionnaire-item"
        v-for="(item, index) in questionnaireList"
        :key="index"
        v-show="item.isShow"
      >
        <div class="number-maxPoints">
          <div class="number">
            <div class="identifier">{{ item.number }}</div>
            <div class="title">{{ item.name }}</div>
          </div>
          <div
            class="maxPoints"
            ref="maxPoints"
            :style="{ fontSize: font ? '' : `${fontSize}px` }"
            v-show="previewWalls.memberRewardVisible"
          >
            {{ item.price | getRmb(previewWalls.ratio, previewWalls.scale)
            }}<span>{{ previewWalls.unitName }}</span>
          </div>
        </div>

        <div class="facility-value" v-show="previewWalls.IRVisible">
          <div class="value">难易度</div>
          <div class="progress-ga">
            <div class="progress-fa">
              <el-progress
                :percentage="item.ir"
                color="#2E67EA"
                :show-text="false"
              />
            </div>
            <div class="percentage">{{ item.ir }}%</div>
          </div>
        </div>

        <div class="clock-join">
          <div class="clock-box">
            <img
              class="clock"
              src="@/assets/images/appLications/clock.png"
              alt="图片"
            />
            <span>{{ item.length }}分钟</span>
          </div>
          <div class="join" @click="join(item.uri)">进入</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAvailableSurveys, getAppWall } from "@/api/app";
export default {
  filters: {
    getRmb(value, number, scale) {
      let data = value * number * scale;
      data = data.toFixed(2);
      data = parseFloat(data);
      return data;
    },
  },
  data() {
    return {
      questionnaireList: [],
      previewWalls: null,
      font: "",
      fontSize: 14,
      appKey: null,
      userId: null,
    };
  },
  created() {
    this.appKey = this.$route.query.appKey;
    this.userId = this.$route.query.user_id;
  },
  mounted() {
    this.getAvailableSurveys();
  },
  methods: {
    //改变颜色
    changeColor(dom, color) {
      document.documentElement.style.setProperty(dom, color);
    },
    //获取调查墙预览信息设置
    getAvailableSurveys() {
      const params = {
        app_id: this.appKey,
        user_id: this.userId,
      };
      getAvailableSurveys(params).then((res) => {
        if (res.status === 200) {
          this.questionnaireList = res.data;
          this.previewWalls = res.extraData;
          console.log(
            "this.previewWalls",
            this.previewWalls,
            this.questionnaireList
          );
          this.questionnaireList.forEach((item, index, array) => {
            if (this.previewWalls.minCpiEnabled) {
              if (this.previewWalls.minCpi <= item.price) {
                array[index].isShow = true;
              } else {
                array[index].isShow = false;
              }
            } else {
              array[index].isShow = true;
            }
          });
        }
        this.$nextTick(() => {
          this.font = this.$refs.maxPoints.every((item) => {
            return item.innerText.length <= 6;
          });
          if (!this.font) {
            this.fontSize = 14;
          }
          const font2 = this.$refs.maxPoints.every((item) => {
            return item.innerText.length <= 7;
          });
          if (!font2) {
            this.fontSize = 12;
          }
          this.changeColor(
            "--background-color-preview",
            this.previewWalls.backgroundColor
          );
          this.changeColor("--font-color-preview", this.previewWalls.fontColor);
        });
      });
    },
    //获取调查墙设置
    //跳转
    join(url) {
      url = url + this.userId;
      console.log("url", url);
      window.open(url);
    },
  },
};
</script>
<style lang="scss" scoped>
:root {
  --background-color-preview: "";
  --font-color-preview: "";
}
$background-preview: var(--background-color-preview);
$font-preview: var(--font-color-preview);

.questionnaire-main {
  width: 100%;
  min-height: 100vh;
  background-color: $background-preview;
  .preview-logo {
    display: flex;
    justify-content: center;
    font-size: 22px;
    img {
      width: 66.2px;
      height: 33.2px;
      margin-top: 15px;
    }
  }
  .questionnaire-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin-top: 20px;
    div {
      margin-left: 10px;
    }
    img {
      width: 20px;
      height: 20px;
      margin-right: 14px;
    }
  }
}
.questionnaire-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: calc(100% - 24px);
  padding-left: 10px;
  padding-right: 14px;
  .questionnaire-item {
    width: 240px;
    background-color: #fff;
    margin-top: 10px;
    border-radius: 4px;
    margin-right: 20px;
    .number-maxPoints {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .number {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        min-width: 100px;
        padding-right: 10px;
        .identifier {
          font-size: 18px;
          font-weight: bold;
          margin-top: 15px;
          color: #06052b;
        }
        .title {
          font-size: 16px;
          font-weight: bold;
          margin-top: 10px;
          margin-left: 0;
          white-space: nowrap;
          word-break: keep-all;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $font-preview;
        }
      }
      .maxPoints {
        min-width: 73px;
        height: 38px;
        line-height: 38px;
        background: #2e67ea;
        box-shadow: 0px 3px 8px 1px rgba(51, 106, 234, 0.55);
        border-radius: 4px 4px 4px 4px;
        margin-right: 11px;
        margin-bottom: 5px;
        color: #fff;
        text-align: center;
        font-size: 18px;
        
        span {
          font-size: 12px;
          margin-left: 2px;
        }
      }
    }
    .facility-value {
      font-size: 12px;
      color: #999999;
      margin-top: 20px;
      margin-left: 10px;
      .progress-ga {
        display: flex;
        align-items: center;
        .progress-fa {
          width: 100px;
        }
        .percentage {
          font-size: 12px;
          color: #999999;
          margin-left: 3px;
        }
      }
    }
    .clock-join {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
      margin-bottom: 18px;
      .clock-box {
        display: flex;
        align-items: center;
        margin-left: 10px;
        img {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
        span {
          color: #666666;
        }
      }
      .join {
        width: 80px;
        height: 28px;
        line-height: 28px;
        margin-right: 10px;
        border-radius: 15px 15px 15px 15px;
        opacity: 1;
        border: 1px solid #2e67ea;
        text-align: center;
        font-size: 14px;
        
        color: #2e67ea;
      }
    }
  }
}
</style>